<template>
    <el-select
        v-model="value"
        filterable
        :multiple="multiple"
        remote
        collapse-tags
        :placeholder="placeholder || '请输入商品名称进行搜索'"
        :remote-method="getProductListApi"
        :loading="loading"
        :clearable="clearable"
        :disabled="disabled || false"
        @clear="onClear"
        @change="onChange"
    >
        <el-option-group label="默认显示20条">
            <el-option v-for="item in productList" :key="item.productNo" :value="item.productNo" :label="item.title">
                <Tag v-if="item.onSale" color="#52c504">上架中</Tag>
                <Tag v-else color="#4f5154">已下架</Tag>
                <span style="margin-left: 10px">{{ item.title }}</span>
            </el-option>
        </el-option-group>
    </el-select>
</template>
<script>
import { getProductList } from '@/js/api/shopApi.js'

export default {
    props: ['defaultValue', 'valueData', 'multiple', 'clearable', 'disabled', 'placeholder'],
    watch: {
        defaultValue: function () {
            if (!this.initState) {
                this.initValue()
            }
        },
        valueData: function () {
            this.updateValue()
        },
    },
    data() {
        return {
            value: '',

            loading: false,
            productList: [],
        }
    },
    created() {
        this.initValue()
    },
    methods: {
        initValue() {
            if (this.defaultValue) {
                getProductList({ productNos: [this.defaultValue] }).then((res) => {
                    this.updateProductList(res.data)

                    this.value = this.defaultValue
                })
            } else {
                this.getProductListApi(null)
            }
            this.initState = true
        },
        updateValue() {
            if (this.valueData) {
                let _state = false
                this.productList.forEach((item) => {
                    if (item.productNo === this.valueData) {
                        this.value = this.valueData
                        _state = true
                    }
                })
                if (!_state) {
                    getProductList({ productNos: [this.valueData] }).then((res) => {
                        this.updateProductList(res.data)
                        this.value = this.valueData
                    })
                }
            } else {
                this.getProductListApi(null)
            }
        },
        onChange(val) {
            this.$emit('change', val)
            this.onItemChange(val)
        },
        onItemChange(val) {
            let _item = []
            this.productList.forEach((i) => {
                if (this.multiple) {
                    if (val && val.includes(i.productNo)) {
                        _item.push(i)
                    }
                } else if (i.productNo === val) {
                    _item.push(i)
                }
            })
            this.$emit('itemChange', this.multiple ? _item : _item[0])
        },
        onClear() {
            this.value = ''
            this.$emit('change', this.value)
            this.getProductListApi(null)
        },
        getProductListApi(query) {
            if (query === '') return
            const params = { page: 1, orderBy: '-id', size: 20, showPage: false }
            if (query) params.title = query
            this.loading = true
            getProductList(params).then((res) => {
                this.loading = false
                this.updateProductList(res.data)
            })
        },
        updateProductList(list) {
            const _list = []
            this.productList.forEach((i) => {
                if (this.multiple && this.value) {
                    if (this.value.includes(i.productNo)) {
                        _list.push(i)
                    }
                } else if (this.value) {
                    if (this.value === i.productNo) {
                        _list.push(i)
                    }
                }
            })
            this.productList = [..._list, ...list]
        },
    },
}
</script>
