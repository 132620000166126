import { post, post_form, begonia_post, hunter_post } from '../http'

export const userInfoList = (data) => {
    return post({ path: '/user/listBig', data })
}

export const userList = (data) => {
    return post({ path: '/user/list', data })
}

export const getTradePayOrder = (data) => {
    return post({ path: '/tradePayOrder/list', data })
}

/**
 * 赠送学员课程
 */
// 添加
export const addCoursePackageUser = (data) => {
    return post({ path: '/coursePackageUser/add', data })
}
// 删除
export const delCoursePackageUser = (data) => {
    return post_form({ path: '/coursePackageUser/del', data })
}
// 列表
export const getCoursePackageUserList = (data) => {
    return post({ path: '/coursePackageUser/list', data })
}

export const getOrderList = (data) => {
    return post({ path: '/tradePayOrderDetail/listWithDetail', data })
}

// 拆分订单
export const getPayCode = (data) => {
    return post_form({ path: '/tradePayOrder/getPayCode', data })
}

export const tradePayOrderRefundAdd = (data) => {
    return post({ path: '/tradePayOrderRefund/add?version=1', data })
}

export const tradePayOrderRefundList = (data) => {
    return post({ path: '/tradePayOrderRefund/list', data })
}

export const tradePayOrderRefundMod = (data) => {
    return post({ path: '/tradePayOrderRefund/mod?version=1', data })
}

export const getOrderRefundDetail = (data) => {
    return post_form({ path: '/tradePayOrderRefund/detail', data })
}

export const getRefundOptHistory = (data) => {
    return post_form({ path: '/tradePayOrderRefund/optHistory', data })
}

export const refundAdviseAdd = (data) => {
    return post({ path: '/tradePayOrderRefundAdvise/add', data })
}
export const refundAdviseList = (data) => {
    return post({ path: '/tradePayOrderRefundAdvise/list', data })
}

export const getRefundReason = () => {
    return post_form({ path: '/tradePayOrderRefund/reasons', data: {} })
}
export const delRefundReason = (data) => {
    return post_form({ path: '/tradePayOrderRefund/reason/del', data })
}
export const saveRefundReason = (data) => {
    return post({ path: '/tradePayOrderRefund/reason/save', data })
}

export const getForbiddenList = (data) => {
    return post({ path: '/forbidden/list', data })
}

export const modUserInfo = (data) => {
    return post({ path: '/user/mod', data })
}

export const fetchOrder = (data) => {
    return begonia_post({ path: '/trade/fetchOrder', data })
}

export const getUserJudgeBelong = (data) => {
    return post_form({ path: '/user/judgeBelong', data })
}

export const userForceExit = (data) => {
    return post_form({ path: '/user/forceExit', data })
}

// 录单
export const fillingOrder = (data) => {
    return post({ path: '/tradePayOrder/fillingOrder', data })
}
// 转班
export const upgradeOrder = (data) => {
    return post({ path: '/tradePayOrder/upgradeOrder', data })
}

export const refundTradePayOrder = (data) => {
    return post_form({ path: '/tradePayOrder/refund', data })
}

export const getOrderDetail = (data) => {
    return post({ path: '/tradePayOrderDetail/list', data })
}

export const refundAdviseDel = (data) => {
    return post_form({ path: '/tradePayOrderRefundAdvise/del', data })
}

export const oppoFollowRecordMod = (data) => {
    return hunter_post({ path: '/oppoFollowRecord/mod', data })
}

export const clazzTeacherStudentDel = (data) => {
    return post_form({ path: '/clazzTeacherStudent/del', data })
}
export const getCourseUserAttendProgressList = (data) => {
    return post({ path: '/courseUserAttendProgress/list', data })
}
// 查询订单归属
export const getOppoUserTradeList = (data) => {
    return hunter_post({ path: '/oppoUserTrade/list', data })
}

export const tradePayOrderPaid = (data) => {
    return post({ path: '/clazzStudent/paid', data })
}

export const getPaidUser = (data) => {
    return hunter_post({ path: '/oppoUserTrade/paid', data })
}

export const getUserPaidState = (userIds) => {
    return post({ path: `/user/paid?userIds=${userIds}` })
}

export const getTalkfunUid2User = (data) => {
    return post_form({ path: '/user/talkfunUid2User', data })
}
