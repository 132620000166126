export const orderStatusList = [
    { name: '未完成', val: 'UNPAID' },
    { name: '已支付', val: 'PAID' },
    { name: '已退款', val: 'REFUND' },
    { name: '已取消', val: 'CANCELED' },
]

export const orderStatusMap = {
    UNPAID: '未完成',
    PAID: '已支付',
    REFUND: '已退款',
    CANCELED: '已取消',
}

export const refundStatusMap = {
    DETAINING: { tag: '', text: '申请退款' },
    RETAINED: { tag: 'success', text: '挽留成功' },
    REVIEW: { tag: 'warning', text: '主管审核' },
    CONSENSUS: { tag: 'warning', text: '出款审核' },
    FINANCIAL: { tag: 'danger', text: '财务打款' },
    REFUND: { tag: 'info', text: '已退款' },
}

export const refundStatusList = [
    { name: '申请退款', val: 'DETAINING' },
    { name: '挽留成功', val: 'RETAINED' },
    { name: '主管审核', val: 'REVIEW' },
    { name: '出款审核', val: 'CONSENSUS' },
    { name: '财务打款', val: 'FINANCIAL' },
    { name: '已退款', val: 'REFUND' },
]

export const stepList = [
    { name: '新生问卷', val: 'QUESTION_PAPER' },
    { name: '入学手册', val: 'MANUAL' },
    { name: '入学手册测试', val: 'MANUAL_TEST' },
    { name: '公众号关注', val: 'MP_SUBSCRIBE' },
    { name: '学习规划', val: 'STUDY_PLAN' },
]

export const lockCategoryList = [
    { val: 'PIRACY', name: '盗版课程' },
    { val: 'COMPETITIVE', name: '竞对' },
    { val: 'ADVERTISE', name: '发广告' },
    { val: 'UNREPAYMENT', name: '未按时还款' },
    { val: 'OTHER', name: '其他' },
]

export const lockCategoryMap = {
    PIRACY: '盗版课程',
    COMPETITIVE: '竞对',
    ADVERTISE: '发广告',
    UNREPAYMENT: '未按时还款',
    OTHER: '其他',
}

export const refundTypeList = [
    { orderState: ['PAID'], name: '订单退费', val: 1, desc: '关课；结算退费' },
    { orderState: ['UNPAID'], name: '定金退费', val: 2, desc: '定金退还' },
    { orderState: ['PAID'], name: '退还多缴费', val: 3, desc: '学员多缴费部分退还；降班或其他' },
]

export const refundTypeMap = {
    1: '订单退费',
    2: '定金退费',
    3: '退还多缴费',
}

export const paidOrderStatusList = [
    { name: '支付定金', val: 'UNPAID' },
    { name: '全额付款', val: 'PAID' },
    { name: '关闭订单', val: 'REFUND' },
]
export const paidOrderStatusMap = {
    UNPAID: '支付定金',
    PAID: '全额付款',
    REFUND: '关闭订单',
}
