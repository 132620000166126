import { post, post_form } from '../http'

export const saveProduct = (data) => {
    return post({ path: '/product/save', data })
}
export const getProductList = (data) => {
    return post({ path: '/product/list', data })
}
export const delProduct = (data) => {
    return post_form({ path: '/product/del', data })
}

export const saveProductCategory = (data) => {
    return post({ path: '/productCategory/save', data })
}
export const getProductCategoryList = (data) => {
    return post({ path: '/productCategory/list', data })
}
export const delProductCategory = (data) => {
    return post_form({ path: '/productCategory/del', data })
}

export const getProviders = (data) => {
    return post({ path: '/tradePayOrder/providers', data })
}

export const addProductDetail = (data) => {
    return post({ path: '/productDetail/add', data })
}

export const getProductDetailList = (data) => {
    return post({ path: '/productDetail/list', data })
}
export const delProductDetail = (data) => {
    return post_form({ path: '/productDetail/del', data })
}
