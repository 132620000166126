<template>
    <el-drawer
        :visible.sync="isShow"
        :before-close="onClose"
        append-to-body
        :title="title"
        size="1100px"
        @opened="onOpened"
    >
        <el-table :data="tableData" stripe border>
            <el-table-column align="center" type="index" label="序号" width="72" />
            <el-table-column align="center" prop="tradeNo" label="商户单号" />
            <el-table-column align="center" prop="outNo" label="交易编号（第三方）" />
            <el-table-column align="center" width="90" label="支付渠道">
                <template slot-scope="scope">
                    <span v-if="orderProviderMap && orderProviderMap[scope.row.tradeProvider]">
                        {{ orderProviderMap[scope.row.tradeProvider]['providerName'] }}
                    </span>
                    <span v-else>{{ scope.row.tradeProvider }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" width="90" prop="packageTitle" label="付款金额">
                <template slot-scope="scope">
                    <NumFormat :num="scope.row.tradeFee / 100" :float="2" />
                </template>
            </el-table-column>
            <el-table-column align="center" width="90" label="订单状态">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.tradeStatus === 'PAID'">{{ orderStatusMap['PAID'] }}</el-tag>
                    <el-tag v-else type="info">{{ orderStatusMap[scope.row.tradeStatus] || '未知' }}</el-tag>
                </template>
            </el-table-column>

            <el-table-column align="center" prop="tradeDetailAddTime" label="创建时间" width="96" />
            <el-table-column align="center" prop="paidTime" label="支付时间" width="96" />
        </el-table>
    </el-drawer>
</template>
<script>
import { getOrderList } from '@/js/api/userApi.js'
import { getProviders } from '@/js/api/shopApi.js'
import { orderStatusMap } from '@/js/config/order.js'

export default {
    props: ['isShow', 'activeItem'],
    data() {
        return {
            orderStatusMap,
            orderProviderMap: null,
            title: '',

            tableData: [],
        }
    },
    methods: {
        onOpened() {
            this.title = '订单详情'
            if (this.activeItem) {
                this.title = `${this.activeItem.user.nickname} - 订单详情`
            }
            this.getOrderListApi()
            this.getProvidersApi()
        },
        onClose() {
            this.$emit('onClose')
        },

        getOrderListApi() {
            getOrderList({
                orderNo: this.activeItem.orderNo,
                page: 1,
                showPage: false,
                orderBy:"",
            }).then((res) => {
                this.tableData = res.data
            })
        },
        getProvidersApi() {
            getProviders({}).then((res) => {
                const orderProviderMap = {}
                res.data.forEach((item) => {
                    orderProviderMap[item.providerNo] = item
                })
                this.orderProviderMap = orderProviderMap
            })
        },
    },
}
</script>
