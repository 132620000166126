<template>
    <div class="page_container">
        <div class="query_header">
            <div class="query_conditions">
                <div class="condition_item">
                    <span class="condition_label">项目</span>
                    <span class="condition_content">
                        <ProjectSelect @change="onProjectNoChange" />
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_content">
                        <el-input
                            placeholder="请输入"
                            @change="onUserIdChange"
                            v-model.trim="queryTypeValue"
                            @keyup.enter.native="onPaginationChange('page', 1)"
                        >
                            <el-select
                                class="query_type"
                                @change="onQuerySelectChange"
                                v-model="queryType"
                                slot="prepend"
                            >
                                <el-option label="手机号" value="telNo"></el-option>
                                <el-option label="学员姓名" value="name"></el-option>
                                <el-option label="学员ID" value="userId"></el-option>
                                <el-option label="学员昵称" value="nickname"></el-option>
                                <el-option label="订单号" value="orderNo"></el-option>
                                <el-option label="商户单号" value="tradeNo"></el-option>
                                <el-option label="交易单号（第三方）" value="outNo"></el-option>
                            </el-select>
                        </el-input>
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">订单状态</span>
                    <span class="condition_content">
                        <el-select v-model="statusIn" placeholder="请选择" multiple clearable>
                            <template v-for="item in orderStatusList">
                                <el-option :label="item.name" :value="item.val" :key="item.val" />
                            </template>
                        </el-select>
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">付费状态</span>
                    <span class="condition_content">
                        <el-select v-model="paidFlag" placeholder="请选择">
                            <el-option label="已付费" :value="true"></el-option>
                            <el-option label="未付费" :value="false"></el-option>
                        </el-select>
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">商品</span>
                    <span class="condition_content">
                        <ProductSelect ref="productSelect" :clearable="true" @change="(val) => (productNo = val)" />
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">订单类型</span>
                    <span class="condition_content">
                        <el-select v-model="productType" placeholder="请选择">
                            <el-option label="全部" :value="0"></el-option>
                            <el-option label="课程" :value="1"></el-option>
                            <el-option label="打赏" :value="2"></el-option>
                        </el-select>
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">下单时间</span>
                    <span class="condition_content">
                        <el-date-picker
                            value-format="yyyy-MM-dd HH:mm:ss"
                            v-model="payStartBetween"
                            type="datetimerange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :default-time="['00:00:00', '23:59:59']"
                        >
                        </el-date-picker>
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">支付时间</span>
                    <span class="condition_content">
                        <el-date-picker
                            value-format="yyyy-MM-dd HH:mm:ss"
                            v-model="paidTimeBetween"
                            type="datetimerange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :default-time="['00:00:00', '23:59:59']"
                        >
                        </el-date-picker>
                    </span>
                </div>
            </div>
            <div class="opt_container">
                <el-button @click="onReset">重置</el-button>
                <el-button @click="onPaginationChange('page', 1)" type="primary">查询</el-button>
                <ExportFile
                    class="float_left_btn"
                    host="begonia"
                    path="/tradePayOrder/export"
                    @before-export="onBeforeExport"
                    v-if="P(1040201)"
                />
            </div>
        </div>
        <el-table :data="tableData" stripe border>
            <el-table-column align="center" type="index" label="序号" width="72" />
            <el-table-column header-align="center" prop="orderNo" label="订单号" />
            <el-table-column header-align="center" prop="productInfo.title" label="商品" />
            <el-table-column align="center" label="学员头像" width="100">
                <template slot-scope="scope">
                    <el-avatar v-if="scope.row.user" :size="42" shape="square" :src="scope.row.user.avatar" />
                    <span v-else>-</span>
                </template>
            </el-table-column>
            <el-table-column label="微信昵称">
                <template slot-scope="scope">
                    <el-popover popper-class="order_popover" placement="right" trigger="click">
                        <div class="user_info">
                            <div>
                                <span class="label">学员ID:</span>
                                <span>{{ scope.row.userContact.userId }}</span>
                            </div>
                            <div>
                                <span class="label">学员姓名:</span>
                                <span>{{ scope.row.userContact.name }}</span>
                            </div>
                            <div>
                                <span class="label">手机号:</span>
                                <span>{{ scope.row.userContact.telNo }}</span>
                            </div>
                            <div>
                                <span class="label">收货地址:</span>
                                <span>{{ scope.row.userContact.address }}</span>
                            </div>
                        </div>
                        <el-link slot="reference" type="primary">{{ scope.row.user.nickname }}</el-link>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column align="center" label="支付状态" width="100">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.status === 'PAID'">{{ orderStatusMap['PAID'] }}</el-tag>
                    <el-tag v-else-if="scope.row.status === 'UNPAID'" type="info">未完成</el-tag>
                    <el-tag v-else type="info">{{ orderStatusMap[scope.row.status] || '未知' }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column align="center" prop="paidTime" label="支付时间" width="96" />
            <el-table-column align="center" prop="addTime" label="创建时间" width="96" />
            <el-table-column align="center" label="已支付(元)" width="100">
                <template slot-scope="scope">
                    <span
                        class="unsuccess_state"
                        v-if="scope.row.paidFee > 0 && scope.row.paidFee !== scope.row.totalFee"
                    >
                        {{ scope.row.paidFee ? scope.row.paidFee / 100 : '-' }}
                    </span>
                    <NumFormat v-else :num="scope.row.paidFee / 100" :float="2" />
                </template>
            </el-table-column>
            <el-table-column align="center" label="总价(元)" width="100">
                <template slot-scope="scope">
                    <NumFormat :num="scope.row.totalFee / 100" :float="2" />
                </template>
            </el-table-column>

            <el-table-column align="center" label="操作" width="80">
                <template slot-scope="scope">
                    <el-link @click="onShowOrderDetail(scope.row)" type="primary">订单详情</el-link>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination
            background
            :page-sizes="[10, 20, 50, 100]"
            @size-change="(val) => onPaginationChange('size', val)"
            @current-change="(val) => onPaginationChange('page', val)"
            :current-page.sync="page"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        />

        <OrderDetail :isShow="isShowOrderDetail" :activeItem="activeItem" @onClose="onCloseOrderDetail" />
    </div>
</template>

<script>
import ProjectSelect from '@/views/components/ProjectSelect'
import ProductSelect from '@/views/components/ProductSelect'
import { getTradePayOrder } from '@/js/api/userApi.js'
import { filterQueryEmpty, isEmptyArray, isUserId } from '@/js/utils.js'
import { orderStatusList, orderStatusMap } from '@/js/config/order.js'
import { getProductList } from '@/js/api/shopApi.js'
import OrderDetail from './components/order/OrderDetail.vue'

let _localStorageKey = 'order_queryType'
export default {
    components: { OrderDetail, ProductSelect, ProjectSelect },
    data() {
        return {
            projectNo: '',
            orderStatusMap,

            queryTypeValue: '',
            queryType: 'userId',
            orderStatusList,
            statusIn: [],
            paidFlag: '',
            // 商品筛选
            productNo: '',
            productType: 0,
            paidTimeBetween: [],
            payStartBetween: [],

            activeOrderInfo: null,
            tableData: [],
            page: 1,
            pageSize: 10,
            total: 0,

            activeItem: null,
            isShowOrderDetail: false,
        }
    },
    created() {
        this.queryType = localStorage.getItem(_localStorageKey) || 'userId'
    },
    methods: {
        onUserIdChange() {
            if (this.queryType === 'userId' && this.queryTypeValue && !isUserId(this.queryTypeValue)) {
                this.queryTypeValue = ''
                this.$message.warning('请输入正确的 学员ID')
            }
        },
        onQuerySelectChange() {
            localStorage.setItem(_localStorageKey, this.queryType)
            this.onUserIdChange()
        },

        onReset() {
            this.queryTypeValue = ''
            this.statusIn = []
            this.paidFlag = ''
            this.productNo = ''
            this.productType = 0
            this.paidTimeBetween = []
            this.payStartBetween = []
            if (this.$refs.productSelect) {
                this.$refs.productSelect.onClear()
            }
            this.onPaginationChange('page', 1)
        },
        onProjectNoChange(val) {
            this.projectNo = val
            this.onReset()
        },

        onPaginationChange(type, value) {
            if (type === 'size') {
                this.pageSize = value
                this.page = 1
            } else {
                this.page = value
            }
            this.$nextTick(this.getTradePayOrderApi)
        },
        // 订单详情
        onShowOrderDetail(item) {
            this.activeItem = item
            this.isShowOrderDetail = true
        },
        onCloseOrderDetail() {
            this.activeItem = null
            this.isShowOrderDetail = false
        },

        onBeforeExport(cb) {
            cb(this.getParams())
        },
        async getTradePayOrderApi() {
            let params = {
                page: this.page,
                size: this.pageSize,
                showPage: true,
                ...this.getParams(),
            }
            params = filterQueryEmpty(params)
            const res1 = await getTradePayOrder(params)
            if (res1.data.list.length) {
                const _productNos = []
                res1.data.list.forEach((i) => {
                    if (!_productNos.includes(i.productNo)) {
                        _productNos.push(i.productNo)
                    }
                })
                const res2 = await getProductList({ productNos: _productNos })
                const _productMap = {}
                res2.data.forEach((item) => {
                    _productMap[item.productNo] = item
                })
                res1.data.list.forEach((item) => {
                    item.productInfo = _productMap[item.productNo]
                })
            }
            this.tableData = res1.data.list
            this.total = res1.data.total
            this.page = res1.data.pageNum
        },
        getParams() {
            let params = { paidFlag: this.paidFlag, projectNo: this.projectNo }
            params.productNos = this.productNo ? [this.productNo] : ''
            params.statusIn = !isEmptyArray(this.statusIn) ? this.statusIn : null
            params.paidTimeBetween = !isEmptyArray(this.paidTimeBetween) ? this.paidTimeBetween : null
            params.payStartBetween = !isEmptyArray(this.payStartBetween) ? this.payStartBetween : null
            params[this.queryType] = this.queryTypeValue
            if (this.productType !== 0) {
                params.productTypes = [this.productType]
            }
            params = filterQueryEmpty(params)
            return params
        },
    },
}
</script>

<style lang="less" scoped>
.unsuccess_state {
    color: red;
    font-weight: 700;
}
.order_popover {
    .user_info {
        > div {
            line-height: 30px;
            display: flex;
            align-items: center;
        }
        span {
            font-weight: 700;
        }
        .label {
            font-weight: 400;
            color: #999;
            padding-right: 12px;
        }
    }
}
</style>
